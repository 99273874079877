import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "overlayText" }
const _hoisted_2 = {
  key: 0,
  class: "centerCards d-flex justify-content-center flex-wrap text-center"
}
const _hoisted_3 = {
  class: "overflow-auto",
  style: {"max-height":"500px"}
}
const _hoisted_4 = { id: "header" }
const _hoisted_5 = { key: 0 }
const _hoisted_6 = { key: 0 }
const _hoisted_7 = { key: 0 }
const _hoisted_8 = { key: 0 }
const _hoisted_9 = {
  class: "overflow-auto",
  style: {"max-height":"700px"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_b_spinner = _resolveComponent("b-spinner")!
  const _component_b_col = _resolveComponent("b-col")!
  const _component_session_leave_button = _resolveComponent("session-leave-button")!
  const _component_estimate_timer = _resolveComponent("estimate-timer")!
  const _component_b_row = _resolveComponent("b-row")!
  const _component_rounded_avatar = _resolveComponent("rounded-avatar")!
  const _component_member_vote_card = _resolveComponent("member-vote-card")!
  const _component_b_icon_three_dots = _resolveComponent("b-icon-three-dots")!
  const _component_session_admin_card = _resolveComponent("session-admin-card")!
  const _component_session_member_card = _resolveComponent("session-member-card")!
  const _component_user_story_sum_component = _resolveComponent("user-story-sum-component")!
  const _component_user_stories = _resolveComponent("user-stories")!
  const _component_user_story_title = _resolveComponent("user-story-title")!
  const _component_user_story_descriptions = _resolveComponent("user-story-descriptions")!
  const _component_notify_member_component = _resolveComponent("notify-member-component")!
  const _component_b_overlay = _resolveComponent("b-overlay")!
  const _component_b_container = _resolveComponent("b-container")!

  return (_openBlock(), _createBlock(_component_b_container, { id: "member-vote-page" }, {
    default: _withCtx(() => [
      _createVNode(_component_b_overlay, { show: _ctx.pauseSession }, {
        overlay: _withCtx(() => [
          _createVNode(_component_b_spinner, { class: "me-2" }),
          _createElementVNode("span", _hoisted_1, _toDisplayString(_ctx.t("page.vote.hostLeft")), 1)
        ]),
        default: _withCtx(() => [
          (!_ctx.isMobile)
            ? (_openBlock(), _createBlock(_component_b_row, {
                key: 0,
                class: "headers"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_b_col, null, {
                    default: _withCtx(() => [
                      _createElementVNode("h1", null, _toDisplayString(_ctx.t("page.vote.title")), 1)
                    ], undefined, true),
                    _: 1
                  }),
                  _createVNode(_component_b_col, { cols: "auto" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_session_leave_button),
                      (_ctx.timerTimestamp)
                        ? (_openBlock(), _createBlock(_component_estimate_timer, {
                            key: 0,
                            class: "mt-3",
                            "start-timestamp": _ctx.timerTimestamp,
                            "pause-timer": _ctx.estimateFinished || _ctx.pauseSession,
                            duration: _ctx.timerCountdownNumber,
                            member: _ctx.memberID,
                            "voting-started": _ctx.isStartVoting
                          }, null, 8, ["start-timestamp", "pause-timer", "duration", "member", "voting-started"]))
                        : _createCommentVNode("", true)
                    ], undefined, true),
                    _: 1
                  })
                ], undefined, true),
                _: 1
              }))
            : (_openBlock(), _createBlock(_component_b_row, {
                key: 1,
                class: "headers mb-2"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_b_col, { class: "align-self-end" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_rounded_avatar, {
                        member: _ctx.getMember,
                        admin: false,
                        mobile: true
                      }, null, 8, ["member"]),
                      _createElementVNode("h1", null, _toDisplayString(_ctx.t("page.vote.title")), 1)
                    ], undefined, true),
                    _: 1
                  }),
                  _createVNode(_component_b_col, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_session_leave_button, { "is-mobile": true }),
                      (_ctx.timerTimestamp)
                        ? (_openBlock(), _createBlock(_component_estimate_timer, {
                            key: 0,
                            class: "mt-3",
                            "start-timestamp": _ctx.timerTimestamp,
                            "pause-timer": _ctx.estimateFinished || _ctx.pauseSession,
                            duration: _ctx.timerCountdownNumber,
                            member: _ctx.memberID,
                            "voting-started": _ctx.isStartVoting
                          }, null, 8, ["start-timestamp", "pause-timer", "duration", "member", "voting-started"]))
                        : _createCommentVNode("", true)
                    ], undefined, true),
                    _: 1
                  })
                ], undefined, true),
                _: 1
              })),
          _createVNode(_component_b_row, null, {
            default: _withCtx(() => [
              (!_ctx.isMobile)
                ? (_openBlock(), _createBlock(_component_b_col, {
                    key: 0,
                    cols: "auto",
                    class: "memberIcon"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_rounded_avatar, {
                        member: _ctx.getMember,
                        admin: false
                      }, null, 8, ["member"])
                    ], undefined, true),
                    _: 1
                  }))
                : _createCommentVNode("", true)
            ], undefined, true),
            _: 1
          }),
          (_ctx.isStartVoting)
            ? (_openBlock(), _createBlock(_component_b_row, { key: 2 }, {
                default: _withCtx(() => [
                  (_ctx.isMobile)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.voteSet, (voteOption, idx) => {
                          return (_openBlock(), _createBlock(_component_member_vote_card, {
                            key: voteOption,
                            ref_for: true,
                            ref: `memberCard${voteOption}`,
                            class: "m-2",
                            "vote-option": voteOption,
                            index: idx,
                            "hex-color": _ctx.hexColor ?? '',
                            dragged: voteOption === _ctx.draggedVote,
                            "is-mobile": true,
                            disabled: _ctx.pauseSession,
                            onSentVote: _ctx.onSendVote
                          }, null, 8, ["vote-option", "index", "hex-color", "dragged", "disabled", "onSentVote"]))
                        }), 128))
                      ]))
                    : (_openBlock(), _createBlock(_component_b_row, {
                        key: 1,
                        class: "centerCards d-flex justify-content-between flex-wrap text-center"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_b_col, null, {
                            default: _withCtx(() => [
                              _createElementVNode("div", _hoisted_3, [
                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.voteSet, (voteOption, idx) => {
                                  return (_openBlock(), _createBlock(_component_member_vote_card, {
                                    key: voteOption,
                                    ref_for: true,
                                    ref: `memberCard${voteOption}`,
                                    style: {"display":"inline-block"},
                                    class: "m-2",
                                    "vote-option": voteOption,
                                    index: idx,
                                    "hex-color": _ctx.hexColor ?? '',
                                    dragged: voteOption === _ctx.draggedVote,
                                    "is-mobile": false,
                                    disabled: _ctx.pauseSession,
                                    onSentVote: _ctx.onSendVote
                                  }, null, 8, ["vote-option", "index", "hex-color", "dragged", "disabled", "onSentVote"]))
                                }), 128))
                              ])
                            ], undefined, true),
                            _: 1
                          })
                        ], undefined, true),
                        _: 1
                      }))
                ], undefined, true),
                _: 1
              }))
            : _createCommentVNode("", true),
          (!_ctx.isStartVoting && !_ctx.votingFinished)
            ? (_openBlock(), _createBlock(_component_b_row, {
                key: 3,
                class: "my-5"
              }, {
                default: _withCtx(() => [
                  _createElementVNode("h3", _hoisted_4, [
                    _createTextVNode(_toDisplayString(_ctx.t("page.vote.waiting")) + " ", 1),
                    _createElementVNode("sub", null, [
                      _createVNode(_component_b_icon_three_dots, {
                        animation: "fade",
                        "font-scale": "1"
                      })
                    ])
                  ])
                ], undefined, true),
                _: 1
              }))
            : _createCommentVNode("", true),
          (_ctx.votingFinished && _ctx.isAdminHighlighted())
            ? (_openBlock(), _createBlock(_component_b_row, {
                key: 4,
                class: "my-1 d-flex justify-content-center flex-wrap overflow-auto",
                style: {"max-height":"500px"}
              }, {
                default: _withCtx(() => [
                  (_ctx.hostEstimation !== undefined)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                        (_ctx.hostVoting && _ctx.hostEstimation.hostEstimation !== null)
                          ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                              (_ctx.hostEstimation.hostEstimation !== null)
                                ? (_openBlock(), _createBlock(_component_session_admin_card, {
                                    key: 0,
                                    "current-estimation": _ctx.hostEstimation.hostEstimation,
                                    "estimate-finished": _ctx.votingFinished,
                                    highlight: _ctx.isAdminHighlighted()
                                  }, null, 8, ["current-estimation", "estimate-finished", "highlight"]))
                                : _createCommentVNode("", true)
                            ]))
                          : _createCommentVNode("", true)
                      ]))
                    : _createCommentVNode("", true),
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.members, (member) => {
                    return (_openBlock(), _createBlock(_component_session_member_card, {
                      key: member.memberID,
                      member: member,
                      props: {
            estimateFinished: _ctx.votingFinished,
            highlight:
              _ctx.highlightedMembers.includes(member.memberID) || _ctx.highlightedMembers.length === 0,
          }
                    }, null, 8, ["member", "props"]))
                  }), 128))
                ], undefined, true),
                _: 1
              }))
            : _createCommentVNode("", true),
          (_ctx.votingFinished && _ctx.isAdminHighlighted() === false)
            ? (_openBlock(), _createBlock(_component_b_row, {
                key: 5,
                class: "my-1 d-flex justify-content-center flex-wrap overflow-auto",
                style: {"max-height":"500px"}
              }, {
                default: _withCtx(() => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.members, (member) => {
                    return (_openBlock(), _createBlock(_component_session_member_card, {
                      key: member.memberID,
                      member: member,
                      props: {
            estimateFinished: _ctx.votingFinished,
            highlight:
              _ctx.highlightedMembers.includes(member.memberID) || _ctx.highlightedMembers.length === 0,
          }
                    }, null, 8, ["member", "props"]))
                  }), 128)),
                  (_ctx.hostEstimation !== undefined)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                        (_ctx.hostVoting && _ctx.hostEstimation.hostEstimation !== null)
                          ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                              (_ctx.hostEstimation.hostEstimation !== null)
                                ? (_openBlock(), _createBlock(_component_session_admin_card, {
                                    key: 0,
                                    "current-estimation": _ctx.hostEstimation.hostEstimation,
                                    "estimate-finished": _ctx.votingFinished,
                                    highlight: _ctx.isAdminHighlighted()
                                  }, null, 8, ["current-estimation", "estimate-finished", "highlight"]))
                                : _createCommentVNode("", true)
                            ]))
                          : _createCommentVNode("", true)
                      ]))
                    : _createCommentVNode("", true)
                ], undefined, true),
                _: 1
              }))
            : _createCommentVNode("", true),
          (_ctx.userStoryMode !== 'NO_US')
            ? (_openBlock(), _createBlock(_component_b_row, {
                key: 6,
                class: "mt-5"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_b_col, { md: "6" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_user_story_sum_component, { class: "ms-4" })
                    ], undefined, true),
                    _: 1
                  })
                ], undefined, true),
                _: 1
              }))
            : _createCommentVNode("", true),
          (_ctx.userStoryMode !== 'NO_US')
            ? (_openBlock(), _createBlock(_component_b_row, {
                key: 7,
                class: "d-flex flex-wrap"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_b_col, {
                    cols: "12",
                    md: "5"
                  }, {
                    default: _withCtx(() => [
                      _createElementVNode("div", _hoisted_9, [
                        _createVNode(_component_user_stories, {
                          "card-set": _ctx.voteSet,
                          "show-estimations": true,
                          "initial-stories": _ctx.userStories,
                          "show-edit-buttons": false,
                          "host-selected-story-index": _ctx.hostSelectedStoryIndex,
                          onSelectedStory: _cache[0] || (_cache[0] = ($event: any) => (_ctx.onSelectedStory($event)))
                        }, null, 8, ["card-set", "initial-stories", "host-selected-story-index"])
                      ])
                    ], undefined, true),
                    _: 1
                  }),
                  (_ctx.index !== null)
                    ? (_openBlock(), _createBlock(_component_b_col, {
                        key: 0,
                        cols: "12",
                        md: "7"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_user_story_title, {
                            host: false,
                            "initial-stories": _ctx.userStories,
                            "card-set": _ctx.voteSet,
                            index: _ctx.index
                          }, null, 8, ["initial-stories", "card-set", "index"]),
                          (_ctx.userStories.length > 0 && _ctx.index < _ctx.userStories.length)
                            ? (_openBlock(), _createBlock(_component_user_story_descriptions, {
                                key: _ctx.userStories[_ctx.index].description,
                                index: _ctx.index,
                                "initial-stories": _ctx.userStories,
                                "edit-description": false
                              }, null, 8, ["index", "initial-stories"]))
                            : _createCommentVNode("", true)
                        ], undefined, true),
                        _: 1
                      }))
                    : _createCommentVNode("", true)
                ], undefined, true),
                _: 1
              }))
            : _createCommentVNode("", true),
          _createVNode(_component_notify_member_component, {
            onHostLeft: _ctx.reactOnHostLeave,
            onHostJoined: _ctx.reactOnHostJoin
          }, null, 8, ["onHostLeft", "onHostJoined"])
        ], undefined, true),
        _: 1
      }, 8, ["show"])
    ], undefined, true),
    _: 1
  }))
}